import React from "react";
import { Content, View } from "@flive/react-kit";
import SEO from "../components/SEO";

const { Title } = Content;

const NotFoundPage = () => (
  <View
    display="flex"
    flexDirection="column"
    justifyContent={{ xs: "center", lg: "flex-start" }}
    padding="mini-3"
    width={{ md: "layout-30", lg: "layout-40" }}
    margin={{ x: "auto" }}
  >
    <SEO title="La page n'existe pas" description="Page introuvable" />
    <Title>La page n'existe pas.</Title>
  </View>
);

export default NotFoundPage;
